<template>
<div class="common">

    <div class="commonTop flex flex-ai-c flex-jc-sb">
        <div class="name flex flex-ai-c" @click="goindex()">新坐标后台管理系统</div>
        <div class="userbox flex flex-jc-sb">
            <div class="user">
                <el-dropdown trigger="click">
                    <span class="el-dropdown-link flex flex-ai-c">
                        {{$root.userInfo.name}}<i class="el-icon-arrow-down" style="margin-left:5px"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <!-- <el-dropdown-item icon="el-icon-s-tools" @click.native="repwd()">修改密码</el-dropdown-item> -->
                        <el-dropdown-item icon="el-icon-s-unfold" @click.native="logOut()">退出登录</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
    </div>

    <div class="commonLeft" :style="{'width':isCollapse?'64px':'200px'}">
        <div class="nav">
            <el-aside>

                <el-menu background-color="#545c64" text-color="#EBF2FF" :default-active="$route.path" active-text-color="#fff" :router="true" :collapse="isCollapse" style="height:calc( 100% - 50px )">
                    <el-menu-item v-for="nav in navList" :index="nav.path" :key="nav.name">
                        <i :class="nav.icon"></i>
                        <span slot="title">
                            {{nav.name}}
                        </span>
                    </el-menu-item>
                    <p style="height:50px;background:#545c64"></p>
                </el-menu>

                <div class="menuBtnbox" :style="{'width':isCollapse?'64px':'200px'}">
                    <i class="el-icon-s-operation menuBtn" @click="handleCollapse()"></i>
                </div>
            </el-aside>
        </div>
    </div>
    <div class="commonRight" :style="{'left':isCollapse?'64px':'200px'}">
        <router-view :isCollapse="isCollapse" />
    </div>

    <el-dialog title="修改密码" :visible.sync="repwdVisible" :modal-append-to-body="false" :close-on-click-modal="false" width="380px" center>
        <el-form ref="formAuth" :model="pwdForm" :rules="formRules" :label-width="formLabelWidth">
            <el-form-item label="原密码" prop="oldPassword" style="margin-bottom:0">
                <el-input size="medium" v-model="pwdForm.oldPassword"></el-input>
            </el-form-item>
            <el-form-item label="新密码" prop="newPassword" style="margin-bottom:0">
                <el-input size="medium" show-password v-model="pwdForm.newPassword"></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="newPassword2" style="margin-bottom:0">
                <el-input size="medium" show-password v-model="pwdForm.newPassword2"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button size="small" type="primary" @click="repwdEnter" style="width:100%">确 定</el-button>
        </div>
    </el-dialog>
</div>
</template>

<script>
import axios from 'axios';
export default {
    data: function() {
        var oldPassword = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('请输入原密码'));
            } else {
                callback();
            }
        };
        var newPassword = (rule, value, callback) => {
            if (!value) {
                callback(new Error('请输入新密码'));
            } else {
                if (this.pwdForm.newPassword !== '') {
                    this.$refs.formAuth.validateField('newPassword2');
                }
                callback();
            }
        };
        var newPassword2 = (rule, value, callback) => {
            if (!value) {
                callback(new Error('请再次输入密码'));
            } else if (value !== this.pwdForm.newPassword) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        };
        return {
            pwdForm: {},
            count: '',
            formRules: {
                oldPassword: [{
                    validator: oldPassword,
                    trigger: 'blur'
                }],
                newPassword: [{
                    validator: newPassword,
                    trigger: 'blur'
                }],
                newPassword2: [{
                    validator: newPassword2,
                    trigger: 'blur'
                }],
            },
            formLabelWidth: '80px', //弹出form label 宽
            repwdVisible: false,
            defaultActive: this.$route.path,
            isCollapse: false,
            userInfo: {},
            navList: [{
                name: '统计概况',
                path: '/overview',
                icon: 'el-icon-menu',
            },{
                name: '视频',
                path: '/video',
                icon: 'el-icon-menu',
            },{
                name: '学生',
                path: '/student',
                icon: 'el-icon-menu',
            },{
                name: '二维码',
                path: '/code',
                icon: 'el-icon-menu',
            },{
                name: '管理员',
                path: '/admin',
                icon: 'el-icon-menu',
            }],
        }
    },
    mounted: function() {
        this.init();
        if (this.$root.userInfo != '') this.userInfo = this.$root.userInfo;
    },
    //导航守卫
    beforeRouteEnter  (to, from, next) {
        axios.post(window.domain + '/admin.admin/read',{
            id:sessionStorage.getItem('userId')
        }).then(re => {
            next( vm => {
                // 通过 `vm` 访问组件实例
                if (re.data.code == 200) {
                    vm.$root.userInfo = re.data.data;
                }else if(re.data.code == 201){
                    vm.$router.push('/');
                }
            })
        })

    },
    methods: {
        init: function() {

        },
        goindex: function() {
            this.$router.push('/video');
        },
        //nav跳转
        navTo: function(path) {
            this.$router.push(path);
        },
        //
        handleCollapse: function() {
            this.isCollapse = !this.isCollapse;
        },
        repwd: function() {
            this.repwdVisible = true
        },
        logOut: function() {
            this.http.post('/admin.login/logout').then(() => {
                this.$router.push('/')
            })
        },
        repwdEnter: function() {
            this.$refs['formAuth'].validate((valid) => {
                if (valid) {
                    this.http.post('/user/setPassword', this.pwdForm).then(() => {
                        this.repwdVisible = false;
                        this.$message({
                            message: '修改成功!',
                            type: 'success'
                        });
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            })
        },
    },
    beforeUpdate: function() {
        if (this.$root.userInfo != '') this.userInfo = this.$root.userInfo;
    },
}
</script>
